<template>
    <b-table-simple responsive fixed>
        <thead>
            <tr>
                <th width="3%">
                    <Check 
                        v-model="masterCheck" 
                        v-b-tooltip.hover :title="masterCheck ? 'Desmarcar todos' : 'Marcar todos'"
                        @change="checked => $emit('handlerMasterSelect', checked)"
                    />
                </th>
                <th><Ellipsis>Identificador</Ellipsis></th>
                <th><Ellipsis>Código</Ellipsis></th>
                <th><Ellipsis>Nome</Ellipsis></th>
                <th><Ellipsis>Tipo</Ellipsis></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in items" :key="index">
                <td>
                    <Check 
                        :value="selecteds.includes(item.id)" 
                        @input="checked => !checked && masterCheck ? (masterCheck = false) : null"
                        @change="$emit('handlerSelect', item)" 
                        :disabled="!item.clinic_id"
                        v-b-tooltip.hover title="Itens do sistema não podem ser excluídos"
                    />
                </td>
                <td>
                    {{ item.identifier }} 
                    <Copy 
                        class="icon" 
                        @click="copy(item.identifier)"
                        v-b-tooltip.hover title="Copiar"
                    />
                </td>
                <td>{{ item.code ?? '-' }}</td>
                <td><Ellipsis>{{ item.name }}</Ellipsis></td>
                <td>{{ parseTypeToLabel(item.type) }}</td>
                <td>
                    <div class="actions">
                        <Edit  
                            class="icon" 
                            v-b-tooltip.hover.rightbottom title="Editar item"
                            @click="() => $emit('edit', item)"
                        />
                        <Delete 
                            v-if="item.clinic_id"
                            class="icon" 
                            v-b-tooltip.hover title="Excluir item"
                            @click="() => deleteItem(item)"
                        />
                    </div>
                </td>
            </tr>
        </tbody>
    </b-table-simple>
</template>

<script>
import { parseTypeToLabel } from '@items/utils/constants'

export default {
    components: {
        Ellipsis: () => import('@/components/General/Ellipsis'),
        Copy: () => import('@items/assets/icons/copy.svg'),        
        Check: () => import('@/components/General/Check'),
        Delete: () => import('@/assets/icons/delete.svg'),
        Edit: () => import('@/assets/icons/edit.svg'),
    },
    props: {
        items: {
            type: Array,
            default: []
        },
        selecteds: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            masterCheck: false
        }
    },
    methods: {
        parseTypeToLabel,
        copy(identifier) {
            navigator.clipboard.writeText(identifier)
            this.$toast.info('Identificador copiado')
        },
        deleteItem(item) {
            this.$swal({
                icon: 'question',
                html: `<div class="body-alert">Deseja realmente excluir o <span>Item ${item.name}</span>?</div>`,
                showCancelButton: true,
                confirmButtonColor: '#F63220',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true
            }).then(res => {
                if (res.isConfirmed) {
                    this.$emit('delete', item)
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

</style>